html * {
    --compute-color: #B843EE;
    --view-color: #00C7E2;
}

.ComputeView {
    color: var(--midnight-GreyUI);
}

.graph-resource-icon {
    height: 25px;
}

.compute-overlay-graph {
    margin-top: 50px;
    padding-top: 10px;
}

.compute-graph-container {
    height: 400px;
}

.compute-graph-container select {
    background: transparent;
    border: none;
    color: var(--midnight-GreyUI);
    height: 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    appearance: none;
}

.compute-graph-container select > .option-enabled {
    color: var(--midnight-LiteUI);
}

.compute-graph-container select:hover {
    color: var(--midnight-LiteUI);
    cursor: pointer;
}

.compute-graph-container select * {
    background: var(--midnight-DeepGrapeUI);
}

.bar {
    box-sizing: content-box;
    height: 24px;
    position: relative;
    margin: 0 10px 0 10px; /* Just for demo spacing */
    border-radius: 25px;
    width: 75%;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
    overflow: hidden;
}

.compute-bar {
    border: 2px solid var(--compute-color);
}

.view-bar {
    border: 2px solid var(--view-color);
}

.bar > span {
    display: block;
    height: 100%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
    overflow: hidden;
}

.bar-container {
    margin: 10px;
    padding: 0 10px 0 10px;
}

.compute-bar > span {
    background-color: var(--compute-color);
}

.view-bar > span {
    background-color: var(--view-color);
}

.compute-text {
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: auto;
}

.usage-number {
    margin-right: auto;
    margin-left: 0px;
    min-width: 35px;
}

.total-number {
    margin-right: 0px;
    margin-left: auto;
    min-width: 35px;
}

.compute-text-color {
    color: var(--compute-color);
}

.view-text-color {
    color: var(--view-color);
}

.compute-small-text {
    font-family: Barlow;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 3px;
}

.usage-total-labels {
    font-family: Barlow;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 10px;
}

.usage {
    position: absolute;
    left: 20px;
}


.total {
    position: absolute;
    right: 20px;
}