.home-content-container {
    background: linear-gradient(90deg, #F6EBEB 0%, #E9E3EB 100%);
    border-radius: 25px;
    height: 261px;
    width: 100%;
    margin-bottom: 8px;
    padding-top: 15px;
}

.home-content-container-alt-bg {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.0) 100%), rgba(255, 255, 255, 0.6);
}

.home-content-container-fit {
    width: fit-content;
    padding-right: 12px;
}

.home-content-container-header {
    display: flex;
    margin-left: 15px;
}

.home-label-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-transform: uppercase;
}

.home-content-area {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding-left: 15px;
    padding-top: 15px;
}

    .home-content-area::-webkit-scrollbar {
        display: none;
    }

.home-content-area {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.home-content-card {
    position: relative;
    width: 200px;
    min-width: 200px;
    height: 200px;
    background: #929292;
    opacity: 0.7;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.home-content-card-label {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-content-card-label-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.home-content-list-container {
    width: calc(100% - 10px);
    height: 100%;
    max-height: 202px;
    overflow-y: scroll;
    overflow-x: scroll;
}

    .home-content-list-container::-webkit-scrollbar {
        display: none;
    }

.home-content-list-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.home-content-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

    .home-content-list-item:nth-child(2n) {
        background: linear-gradient(90deg, #E4D0D4 0%, #DACAD4 100%);
    }

.home-content-list-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.home-content-list-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    margin-right: 12px;
}

.home-content-list-icon-left {
    width: 32px;
    height: 32px;
    left: 16px;
    top: 9px;
    background: rgba(146, 146, 146, 0.6);
    margin-left: 12px;
    margin-right: 8px;
}

.home-content-list-icon-right {
    width: 32px;
    height: 32px;
    right: 16px;
    top: 9px;
    background: rgba(146, 146, 146, 0.6);
    margin-right: 12px;
    margin-left: 8px;
}

.home-content-list-icon-left-round {
    width: 40px;
    height: 40px;
    left: 5px;
    top: 5px;
    margin-left: 12px;
    margin-right: 5px;
    background: rgba(146, 146, 146, 0.6);
    border-radius: 5px;
}
