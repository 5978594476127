.SingleClipView {
    color: var(--midnight-GreyUI);
}

.clip-preview-container {
    margin-top: 4px;
    margin-bottom: 4px;
}

.clip-preview-container:hover {
    margin-top: 0px;
    margin-bottom: 0px;
}


.user-logo-container-for-single-clip-view {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 54px;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single-clip-view-text-modifier {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.single-clip-first-column {
    display: inline-block;
    float: left;
    justify-content: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.single-clip-second-column {
    display: inline-block;
    min-width: 200px;
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dropdown-container select {
    background: transparent;
    border: none;
    color: var(--midnight-GreyUI);
    height: 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    appearance: none;
}

.dropdown-container select > .option-enabled {
    color: var(--midnight-LiteUI);
}

.dropdown-container select:hover {
    color: var(--midnight-LiteUI);
    cursor: pointer;
}

.SingleClipView .clip-link-button {
    background: url("@/images/viewport/singleclipview/Clip_link_icon.svg");
}

.SingleClipView .clip-link-button:hover {
    background: url("@/images/viewport/clipsview/Clip_link_icon_hover.svg");
}

.SingleClipView select * {
    background: var(--midnight-DeepGrapeUI);
}