.viewport {
    min-height: 100vh;
    /* subtract sidebar's width */
    width: calc(100vw - 54px);
    margin-left: 54px;
    background: linear-gradient(90deg, var(--midnight-MainDarkBG) 0%, var(--midnight-CoolGrapeBG) 100%);
    display: flex;
    justify-content: center;
    /* show sidebar hovers on top of viewport */
    z-index: 1;
    transition: margin-left 1s;
}

.viewport-sidebar-hidden {
    min-height: 100vh;
    min-width: 100vw;
    margin-left: 0;
}

.ViewportView {
    width: calc(100% - 20px * 2);
    height: 100%;
    font-size: 20px;
    font-weight: bold;
}

.hidden-scroll::-webkit-scrollbar {
    display: none;
}

.hidden-scroll {
    overflow: scroll;
    /* NOTE (MS) align-items: end improves visualization when expanding container */
    align-items: end;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.portal-button {
    height: 36px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    background-color: var(--midnight-DarkGrape);
    color: var(--midnight-GreyUI);
    border-radius: 3px;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
    padding-left: 15px;
    padding-right: 15px;
}

.portal-button-disabled {
    height: 36px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    background-color: var(--midnight-DarkGrape);
    color: var(--midnight-GreyUI);
    opacity: 0.4;
    padding-left: 15px;
    padding-right: 15px;
}

.portal-button {
    cursor: pointer;
}

.portal-button label {
    width: 100%;
    cursor: pointer;
}

.portal-button input {
    cursor: pointer;
}

.portal-button:hover {
    background-color: var(--midnight-DeepGrapeUI);
    color: var(--midnight-LiteUI);
}

/* Begin: Graph code for single clip view and compute view */
/* hack (MS): to show graph labels on the right of graph */
.rv-xy-plot__inner {
    width: 110%;
}

.overlay-graph {
    position: absolute;
    padding-top: 25px;
    padding-right: 40px;
    width: 95%;
    height: 85%;
}

.graph-container {
    position: relative;
    width: 95%;
    height: 350px;
    background: linear-gradient(90deg, var(--first-graph-container-color) 0%, var(--second-graph-container-color) 100%);
    border-radius: 25px;
    display: flex;
    justify-content: center;
}

.graph-label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    margin-left: 11px;
}
/* End: Graph code for single clip view and compute view */


/* Begin: portal-list-item used for single clip view, portal-clip-list-item component, and portal-list-item component */
.portal-list-item {
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, var(--midnight-MainGreyFG) 0%, var(--midnight-WarmGrapeFG) 100%);
    border-radius: 25px;
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
}

.portal-list-item-content {
    margin-top: 15px;
    margin-bottom: 15px;
}

.portal-list-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    color: var(--midnight-GreyUI);
}

.portal-list-item-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-right: 20px;
    word-wrap: break-word;
    text-align: left;
    display: flex;
    align-items: center;
}
/* End: portal-list-item used for single clip view, portal-clip-list-item component, and portal-list-item component */


.triangle {
    width: 32px;
    height: 32px;
    position: relative;
    display: inline-block;
    transition: transform .25s linear;
    transform: rotate(0deg);
}

.filled-triangle {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.triangle:hover .filled-triangle {
    display: inline;
    cursor: pointer;
    opacity: 1;
}

.triangle.rotated {
    transform: rotate(-90deg);
}

/*used for hiding/showing content (adding a transition property provides a CSS animation) */
#content {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    vertical-align: bottom;
}

.downloads-content {
    transition: .25s ease-in-out;
}

/* HACK (MS): don't have position:absolute items taking space when content is hidden. This prevents white space in the bottom of the page */
.downloads-content .LTS-sign {
    position: relative;
    width: 0px;
    height: 0px;
}

.downloads-expanded .LTS-sign {
    position: absolute;
    width: 349px;
    height: 38px;
}

.user-content {
    transition: .25s ease-in-out;
}

.clips-content {
    transition: .25s linear;
}

.inside {
    min-width: 0;
    min-height: 0;
    vertical-align: bottom;
}

.inside-portal-list-item {
    display: flex;
}

#content.expanded {
    grid-template-rows: 1fr;
    overflow: visible;
}

#content.downloads-expanded {
    grid-template-rows: 1fr;
}
/*end of used for hiding/showing content*/


.userImageCroppingContainer {
    position: fixed;
    background: linear-gradient(90deg, var(--midnight-MainGreyFG) 0%, var(--midnight-WarmGrapeFG) 100%);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 50px;
    border-radius: 5px;
    z-index: 999;
}

.dialog-window-background-blocker {
    position: fixed;
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

#clip-thumbnail-creator {
    margin-top: 10px;
    margin-left: 10px;
    position: relative;
}