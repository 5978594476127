/* same size specs from profile-input, used for manually adjusting height of columns with less elements than others */
.profile-input-filler {
    min-width: 280px;
    height: 48px;
    border-radius: 3px 3px 0px 0px;
    margin-bottom: 30px;
    display: flex;
    align-content: flex-end;
}

.profile-input, .profile-input-disabled {
    min-width: 280px;
    height: 48px;
    border-radius: 3px 3px 0px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--midnight-DarkGrape);
    margin-bottom: 30px;
    margin-right: 20px;
}

.profile-input:hover {
    background: var(--midnight-DeepGrapeUI);
}

.profile-input:focus-within {
    background: var(--midnight-DeepGrapeUI);
}

.profile-input label {
    position: absolute;
    left: 16px;
    top: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--midnight-MidGrape);
}

.profile-input-disabled label {
    position: absolute;
    left: 16px;
    top: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--midnight-MidGrape);
}

.profile-input-bottom-border {
    width: 100%;
    height: 0px;
    left: 0px;
    bottom: 0px;
    border-top: 1px solid var(--midnight-MidGrape);
    position: absolute;
}

.profile-input input {
    background: transparent;
    border: none;
    position: absolute;
    width: calc(100% - 16px);
    height: 22px;
    left: 16px;
    top: 21px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
    color: var(--midnight-LiteUI);
}

.profile-input-disabled input {
    background: transparent;
    border: none;
    position: absolute;
    width: calc(100% - 16px);
    height: 22px;
    left: 16px;
    top: 21px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
    color: var(--midnight-LiteUI);
}

.profile-input select {
    background: transparent;
    border: none;
    position: absolute;
    width: calc(100% - 25px);
    height: 22px;
    top: 21px;
    left: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
    color: var(--midnight-LiteUI);
}

.profile-input select * {
    color: var(--midnight-LiteUI);
    background: var(--midnight-DeepGrapeUI);
}

.profile-input input:focus {
    outline: none;
}

.profile-input select:focus {
    outline: none;
}

.profile-input:hover .profile-input-bottom-border {
    border-top: 1px solid var(--midnight-MidGrape);
}

.profile-input:focus-within .profile-input-bottom-border {
    border-top: 2px solid var(--midnight-MidGrape);
}

.profile-input-error-message {
    /*styleName: mini-help;*/
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    /*width: 264px;*/
    position: absolute;
    height: 18px;
    top: 52px;
    left: 5px;
    color: var(--midnight-MidGrape);
}

.user-only-column {
    margin-right: auto;
    margin-left: auto;
    width: 80%
}

.user-2-or-3-columns {
    width: 90%;
}


.user-save-button, .user-save-button-disabled {
    width: 64px;
    height: 36px;
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    margin-left: auto;
    margin-bottom: 20px;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 3px;
    position: relative;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
    background-color: var(--midnight-DarkGrape);
    color: var(--midnight-GreyUI);
}

.user-save-button-disabled {
    opacity: 0.4;
}

.user-save-button {
    cursor: pointer;
}

.user-save-button:hover {
    background: var(--midnight-DeepGrapeUI);
    color: var(--midnight-LiteUI);
}

.user-button-hovered, .user-button-pressed {
    opacity: 0;
    position: absolute;
    z-index: 99;
}

.user-button:hover .user-button-hovered {
    opacity: 1;
}

.user-button:active .user-button-pressed {
    opacity: 1;
}

.user-button {
    position: relative;
    width: 54px;
    height: 54px;
    cursor: pointer;
}

.user-logout-container {
    width: 50%;
    margin-left: 35px;
    position: relative;
}

.user-invite-container {
    margin-left: 10px;
}

.invite-member-popup {
    background: var(--midnight-WarmGrapeFG);
    height: 100vh;
    width: 100vw;
    opacity: 0.85;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.invite-title {
    color: var(--midnight-LiteUI);
    height: 40px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}