
/* Begin: clip-link-button used for clip list row component, single clip view, and portal-clip-list-item component */
.clip-link-button:hover {
    background: url("@/images/viewport/clipsview/Clip_link_icon_hover.svg");
}

.clip-link-button {
    cursor: pointer;
    background: url("@/images/viewport/clipsview/Clip_link_icon.svg");
    height: 50px;
    width: 50px;
    position: relative;
    border-radius: 4em;
    color: white;
}

.clip-link-button:after {
    content: "";
    background: url("@/images/viewport/clipsview/Clip_link_icon_hover.svg");
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
}

.clip-link-button:active:after {
    background: url("@/images/viewport/clipsview/Clip_link_icon_hover.svg");
    transform: scale(1.75);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
}
/* End: clip-link-button used for clip list row component, single clip view, and portal-clip-list-item component */

.clip-list-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
}

.clip-list-subtext {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
    margin-left: 2px;
}

.clip-list-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 8px;
}

.clip-list-name:hover {
    text-decoration: underline;
    color: var(--midnight-LiteUI);
}

.clip-list-text-last-updated {
    min-width: 90px;
    max-width: 300px;
}

.clip-list-version-number {
    min-width: 30px;
    max-width: 300px;
    text-align: left;
}

.clip-list-text-duration {
    min-width: 100px;
    max-width: 300px;
    text-align: left;
}

.clip-list-text-views {
    min-width: 100px;
    max-width: 300px;
    text-align: left;
}