.portal-clip-list-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    height: 50px;
    width: calc(100% - 15px * 2);
}

.project-container {
    border: 2px solid rgba(0,0,0,0);
}

.project-container-dragging:hover {
    border: 2px solid var(--midnight-LiteUI);
}

.project-title {
    display: block;
}

.project-menu-button {
    visibility: hidden;
}

.project-menu-button:hover {
    visibility: visible;
}

.project-item-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 0px;
    /* display: block important to show ellipsis! */
    display: block;
}

.project-menu-item {
    width: 146px;
}

.project-menu-item-disabled {
    width: 146px;
    height: 28px;
    padding: 3px 0px 3px 8px;
    color: var(--midnight-GreyUI);
    /*styleName: Standard;*/
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.portal-clip-list-item-header:hover .project-menu-button {
    visibility: visible;
}

.portal-clip-list-item-header:hover .clip-menu-container {
    position: relative;
}

.collapsed-folder-icon {
    cursor: pointer;
    background: url("@/images/viewport/clipsview/portalcliplistitem/FolderClosed_icon.svg");
    height: 50px;
    min-width: 50px;
}

.collapsed-folder-icon:hover {
    background: url("@/images/viewport/clipsview/portalcliplistitem/FolderClosed_hover.svg");
}

.expanded-folder-icon {
    cursor: pointer;
    background: url("@/images/viewport/clipsview/portalcliplistitem/FolderOpen_icon.svg");
    height: 50px;
    min-width: 50px;
}

.expanded-folder-icon:hover {
    background: url("@/images/viewport/clipsview/portalcliplistitem/FolderOpen_hover.svg");
}
