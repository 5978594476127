.username {
    margin-right: 7px;
}

.sidebar {
    width: 54px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    /* show sidebar hovers on top of viewport */
    z-index: 2;
    position: fixed;
    background: var(--midnight-MainGreyFG);
    margin-left: 0px;
    transition: margin-left .2s;
}

.main-sidebar {
    height: 100%;
}

#hidden-sidebar {
    margin-left: -54px;
}

.hidden-sidebar-button {
    width: 50px;
    height: 50px;
    margin-top: 2px;
    position: fixed;
    visibility: visible;
    background: url("@/images/sidebar/Hidden_Sidebar_icon.svg");
    /* show button on top of viewport */
    z-index: 2;
}

.hidden-sidebar-button:hover {
    background: url("@/images/sidebar/Hidden_Sidebar_icon_hover.svg");
    cursor: pointer;
}

.hidden-sidebar-button:active {
    background: url("@/images/sidebar/Hidden_Sidebar_icon_pressed.svg");
}

.org-icon-in-sidebar {
    position: absolute;
    bottom: 55px;
    left: 11px;
}

.holosuite-icon-in-sidebar {
    position: absolute;
    bottom: 5px;
    left: 11px;
}

.org-icon-in-sidebar:hover ~ #org-label-in-sidebar {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s;
    width: auto;
}

#org-label-in-sidebar:hover {
    visibility: visible;
    opacity: 1;
}

#org-label-in-sidebar {
    visibility: hidden;
    opacity: 0;
}

#org-label-in-sidebar {
    position: absolute;
    bottom: 45px;
    left: 11px;
    display: inline-block;
    padding: 5px;
    background-color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--midnight-DarkGrape);
    border-radius: 3px;
    box-shadow: 0px 3px 12px 0px var(--midnight-MainGreyFG);
    white-space: nowrap;
}


.sidebar-button {
    cursor: pointer;
    height: 54px;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active-sidebar-button {
    background: var(--midnight-MainDarkBG);
    height: 54px;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-hover-panel {
    visibility: hidden;
    position: absolute;
    left: 54px;
    width: 120px;
    height: 54px;
    background: var(--midnight-MainGreyHover);
    padding-right: 5px;
    color: var(--midnight-White);
    display: flex;
    justify-content: left;
    align-items: center;
}

.sidebar-button:hover {
    background: var(--midnight-MainGreyHover);
}

.sidebar-button:hover .sidebar-hover-panel {
    visibility: visible;
}

.sidebar-logout:hover {
    filter: brightness(200%);
    transition: filter 1s linear;
}

.sidebar-button-image {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(281deg) brightness(102%) contrast(103%);
    height: 36px;
}

.active-sidebar-button-image {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(281deg) brightness(102%) contrast(103%);
    height: 36px;
}