.status-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding-top: 2px;
    color: var(--midnight-MidGrape);
    min-width: 150px;
}

.status-text-current {
    color: var(--midnight-MidGrape);
}

.status-text-not-current {
    width: 150px;
    height: 25px;
    border: 2px solid var(--midnight-MidGrape);
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    visibility: hidden;
}

.portal-list-item-header:hover .status-text-not-current {
    visibility: visible;
}

.status-text-not-current:hover {
    color: var(--midnight-LiteUI);
    border: 2px solid var(--midnight-LiteUI);
    background-color: var(--midnight-DarkGrape);
}

.image-selector {
    position: absolute;
    width: 142px;
    top: 2px;
    visibility: hidden;
}

.user-image-selector {
    right: 55px;
    top: 6px;
}

.org-image-selector {
    left: 40px;
}

.image-container {
    position: relative;
}

.image-container:hover .image-selector {
    visibility: visible;
}

.user-logo-container {
    height: 100%;
    padding-left: 20px;
    min-height: 50px;
    min-width: 50px;
    max-width: 50px;
}

.image-selector label {
    position: absolute;
    left: 12px;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.image-selector input {
    opacity: 0;
    visibility: hidden;
}