.normal-text {
    font-size: 13px;
    font-weight: 400;
    color: var(--midnight-LiteUI);
    line-height: 22px;
}

.login-sidebar {
    position: absolute;
    width: 360px;
    min-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(168.44deg, var(--midnight-MainGreyFG) 0%, var(--midnight-WarmGrapeFG) 100%);
}

.sign-up-sidebar {
    min-height: 700px;
}


.signup-prompt {
    margin: 20px
}

.login-content {
    margin-left: 40px;
    margin-right: 40px;
}

.login-help-error {
    height: 32px;
}

.login-welcome-text {
    width: 276px;
    height: 88px;
    left: calc(50% - 276px/2 - 2px);
    top: 27px;
    text-align: left;
    color: var(--midnight-LiteUI);
    margin-left: 44px;
    margin-top: 27px;
    margin-bottom: 27px;
    font-size: 16px;
}

.login-link {
    font-weight: 600;
    line-height: 22px;
    color: var(--midnight-LiteUI);
    text-decoration: underline;
    cursor: pointer;
}

.login-link a, a:link, a:visited {
    font-weight: 600;
    line-height: 22px;
    color: var(--midnight-LiteUI);
}

.login-input, .login-input-disabled {
    width: 280px;
    height: 48px;
    background: var(--midnight-MidGrape);
    border-radius: 3px 3px 0px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
}

.login-input input {
    color: var(--midnight-DeepGrapeUI);
}

.login-input:hover {
    background: var(--midnight-LiteGrape);
}

.login-input:focus-within {
    background: var(--midnight-LiteGrape);
}

.login-input label {
    position: absolute;
    left: 16px;
    top: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--midnight-LiteUI);
}

.login-input-disabled label {
    position: absolute;
    left: 16px;
    top: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--midnight-LiteUI);
}

.login-input-disabled input {
    background: transparent;
    border: none;
    position: absolute;
    width: 225px;
    height: 22px;
    left: 16px;
    top: 21px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
}

.login-input input {
    background: transparent;
    border: none;
    position: absolute;
    width: 225px;
    height: 22px;
    left: 16px;
    top: 21px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
}

.login-input input:focus {
    outline: none;
}

.login-input-bottom-border {
    width: 100%;
    height: 0px;
    left: 0px;
    bottom: 0px;
    border-top: 1px solid var(--midnight-LiteUI);
    position: absolute;
}

.login-input:hover .login-input-bottom-border {
    border-top: 1px solid white;
}

.login-input:focus-within .login-input-bottom-border {
    border-top: 2px solid white;
}

.signup-input-error-message {
    /*styleName: mini-help;*/
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    width: 300px;
    position: absolute;
    height: 18px;
    top: 52px;
    left: -5px;
    color: var(--midnight-GreyUI);
}

.login-footer-container {
    position: absolute;
    bottom: 0px;
    height: 54px;
    width: 360px;
}

.login-footer-image {
    max-width: 220px;
}

.login-confirm-button {
    width: 280px;
    height: 36px;
    left: calc(50% - 280px/2);
    top: 9px;
    background: var(--midnight-MidGrape);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
    cursor: pointer;
}

.login-confirm-button:hover {
    background: var(--midnight-LiteGrape);
}

.login-confirm-button:hover .login-confirm-button-text {
    color: var(--midnight-DeepGrapeUI);
}

.login-confirm-button-disabled {
    width: 280px;
    height: 36px;
    left: calc(50% - 280px/2);
    top: 9px;
    background: var(--midnight-MidGrape);
    opacity: 0.3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
}

.login-confirm-button-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--midnight-CoolGrapeBG);
    -webkit-transition: color 250ms linear;
    -ms-transition: color 250ms linear;
    transition: color 250ms linear;
}

.login-confirm-button-disabled .login-confirm-button-text {
    color: var(--midnight-DarkGrape);
}   

.main-brand {
    background: url("@/images/welcome/Login_Splash.png"),no-repeat fixed;
    background-size: cover;
    display: flex;
    height: 100%;
    justify-content: center;
    width: calc(100% - 350px);
    position: absolute;
    margin-left: 350px;
    z-index: -1;
}

.welcome-to-img {
    width: 665px;
    position: absolute;
    top: 149px;
    left: 265px;
}

@media only screen and (max-width: 1300px) {
    .welcome-to-img {
        visibility: hidden;
        width: 0px;
    }
}

@media only screen and (max-height: 800px) {
    .sign-up-sidebar .login-footer-image {
        visibility: hidden;
        width: 0px;
    }
}

.login-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.checkbox {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
}

.welcome-header-title-text {
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 34px;
    opacity: 0.75;
    margin-top: 15px;
}

.welcome-header-title-text-bold {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    opacity: 0.75;
    margin-top: 15px;
}

.welcome-header-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.75;
    text-align: left;
    margin-top: 9px;
    margin-bottom: 11px;
}

.forgot-pass-link:hover {
    text-decoration: underline;
}

.forgot-pass-link {
    cursor: pointer;
}

.forgot-pass-link-disabled {
    color: var(--midnight-GreyUI)
}