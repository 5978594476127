html * {
    font-family: 'RM Neue', sans-serif;
    --first-graph-container-color: #1A1A25;
    --second-graph-container-color: #1C1142;
    --midnight-White: #FFFFFF;
    --midnight-LiteGrape: #B7AFCC;
    --midnight-GreyUI: #B7B4C3;
    --midnight-MidGrape: #A79FBD;
    --midnight-MainGreyHover: #5F6180;
    --midnight-WarmGrapeFG: #483873;
    --midnight-MainGreyFG: #4A4B63;
    --midnight-CoolGrapeBG: #1C065F;
    --midnight-MainDarkBG: #1A1A25;
    --midnight-DarkGrape: #261F3D;
    --midnight-DeepGrapeUI: #12121F;
    --midnight-LiteUI: #EDEBF5;
}

/* for clips/projects renaming */
[contenteditable] {
    outline: 0px solid transparent;
}

/* for clips page custom tooltips*/
[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.8s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    height: 20px;
    padding: 2px 12px 3px 12px;
    border-radius: 3px;
    background-color: var(--midnight-MidGrape);
    color: var(--midnight-DeepGrapeUI);
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -1.6em;
    /* copy shadow from Figma */
    box-shadow: 0px 2px 8px 1px #522D5680;
    white-space: nowrap;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}
[data-title] {
    position: relative;
}

.App {
    text-align: center;
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    background-color: var(--midnight-MainDarkBG);
}

.spinner {
    position: absolute;
    width: calc(100% - 84px);
    top: 50%;
    margin-top: -50px;
}

.user-initials-container {
    position: absolute;
    top: 6px;
    width: 30px;
}

.user-initials {
    font-size: 16px;
    font-weight: 500;
    color: var(--midnight-CoolGrapeBG);
    left: 3px;
    z-index: 1;
}

.invert-text {
    color: var(--midnight-LiteUI);
}

.red-text {
    color: var(--midnight-LiteUI) !important;
}

.horizontal-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.horizontal-container-align-right {
    margin-left: auto;
    padding-right: 10px;
    align-items: center;
}

.horizontal-container-align-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: auto;
}

.vertical-container{
  display: flex;
  flex-direction: column;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-vert {
    align-items: center;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.full-size-image{
  height:  100%;
  width:  100%;
}

.align-center {
    margin-left: auto;
    margin-right: auto;
}

.align-left {
    display: flex;
    margin-right: auto;
    justify-content: left;
}

.align-left-not-justify {
    display: flex;
    margin-right: auto;
}

.downloads-header-text {
    color: var(--midnight-MidGrape);
    font-weight: 500;
    font-size: 22px;
    line-height: 26.4px;
}

.align-right {
    display: flex;
    margin-left: auto;
    justify-content: right;
}

.align-right-not-justify {
    display: flex;
    margin-left: auto;
}

.justify-left {
    justify-content: left;
}

.bold-text {
    font-weight: 600;
}

/* code Used for user and org logo components (could use some renaming!)*/
.user-display-picture {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--midnight-CoolGrapeBG);
    background: var(--midnight-MidGrape);
}

.invert-border {
    border: 1px solid var(--midnight-LiteUI);
    background: var(--midnight-MainGreyFG);
}

/* Extra to centre the image */
.user-display-picture img {
    width: 30px;
    object-fit: scale-down;
}

.hidden-user-photo {
    visibility: hidden;
}

.user-photo {
    position: relative;
    z-index: 2;
    background: var(--midnight-MidGrape);
}

.invert-border .user-photo {
    background: var(--midnight-MainGreyFG);
}

/* end of code Used for user and org logo components */
.eye-icon-for-password {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--midnight-MidGrape);
}