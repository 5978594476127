
.clip-preview-container {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: var(--midnight-MidGrape);
    overflow: hidden;
    position: relative;
    margin-right: 5px;
    margin-left: 5px;
}

.clip-preview-container:hover {
    width: 48px;
    height: 48px;
    margin-left: 0px;
    margin-right: 2px;
}

.clip-preview-container:hover .clip-play-icon {
    opacity: 1;
}

.clip-preview {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.clip-play-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 75%;
    height: 75%;
    z-index: 2;
    opacity: 0;
}
