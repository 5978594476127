.ClipsView {
    color: var(--midnight-GreyUI);
}

.table-header {
    display: inline-block;
    width: calc(100% - 15px * 2);
    height: 50px;
    /* for better alignment of right border of images with column text */
    padding-left: 20px;
}

.table-header-image-container {
    display: flex;
    height: 100%;
    min-width: 50px;
    max-width: 50px;
}

.table-header-image-container-no-hover {
    display: flex;
    height: 100%;
    min-width: 50px;
    max-width: 50px;
}

.table-header-first-element {
    margin-left: 15px;
}

.table-image-first-element {
    margin-left: 45px;
}

.table-image-align-right {
    margin-left: auto;
}

/* Match clip name table header to clip name as in design */
.table-image-align-left {
    margin-left: 31px;
}

.table-image-align-center {
    margin-left: auto;
    margin-right: auto;
}

.table-header-image-container:hover {
    background: url("@/images/viewport/clipsview/Filter_hover.svg");
    cursor: pointer;
}

.table-header-image-container:active {
    background: url("@/images/viewport/clipsview/Filter_pressed.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.header-image {
    /* convert image color to --midnight-MidGrape (#A79FBD) */
    filter: brightness(0) saturate(100%) invert(74%) sepia(7%) saturate(804%) hue-rotate(215deg) brightness(88%) contrast(88%);
}

.header-image-selected {
    /* convert image color to --midnight-LiteUI (#EDEBF5) */
    filter: brightness(0) saturate(100%) invert(96%) sepia(4%) saturate(476%) hue-rotate(207deg) brightness(99%) contrast(93%);
}

.header-image:active {
    /* convert image color to --midnight-LiteUI (#EDEBF5) */
    filter: brightness(0) saturate(100%) invert(96%) sepia(4%) saturate(476%) hue-rotate(207deg) brightness(99%) contrast(93%);
}

/* for clips page user logo custom tooltips positioning */
.user-picture-container:after {
    bottom: 5px;
    right: 42px;
    transform: initial;
    left: initial;
    top: initial;
}

.search-box {
    box-sizing: border-box;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    right: 0px;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--midnight-MidGrape);
    padding-left: 10px;
}

.search-input {
    border: 0;
    border-bottom: 1px solid white;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    width: 145px;
    background: var(--midnight-MidGrape);
    color: var(--midnight-LiteUI);
}

.search-box-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 5px;
    right: -5px;
    transition: width .25s linear;
    overflow: hidden;
}

.search-cancel {
    margin-right: 12px;
    background: url("@/images/viewport/clipsview/searchBarCancel.svg");
    height: 18px;
    width: 18px;
    overflow: hidden;
}

.search-cancel:hover {
    background: url("@/images/viewport/clipsview/searchBarCancel_hover.svg");
}

.clip-menu-button {
    cursor: pointer;
    background: url("@/images/viewport/clipsview/Clip_menu_icon.svg");
    height: 50px;
    width: 28px;
    position: relative;
    display: block;
}

.clip-menu-button:hover {
    background: url("@/images/viewport/clipsview/Clip_menu_icon_hover.svg");
}

.clip-menu {
    position: absolute;
    z-index: 9;
    left: 30px;
    top: 5px;
    width: 158px;
    height: 164px;
    background: var(--midnight-MidGrape);
    padding: 3px 0px 3px 0px;
    border-radius: 3px;
    box-shadow: 0px 2px 8px 1px #522D5680;
}

.move-to-item {
    position: relative;
}

.move-to-icon {
    position: absolute;
    right: 5px;
    transform: rotate(270deg);
    /* convert image color to --midnight-DeepGrapeUI (#12121F) */
    filter: brightness(0) saturate(100%) invert(4%) sepia(33%) saturate(1784%) hue-rotate(209deg) brightness(105%);
}

.move-to-item:hover .move-to-menu {
    visibility: visible;
}

.move-to-menu:hover {
    visibility: visible;
}

.move-to-menu {
    visibility: hidden;
    position: absolute;
    left: 158px;
    bottom: 0px;
    background: var(--midnight-MidGrape);
    overflow-x: hidden;
    border-radius: 3px;
    box-shadow: 0px 2px 8px 1px #522D5680;
    padding-left: 2px;
    max-height: 240px;
}

.project-menu {
    width: 154px;
    height: 100px;
}

.clip-menu-item, .clip-menu-item label {
    width: 150px;
    height: 28px;
    padding: 3px 0px 3px 0px;
    color: var(--midnight-DeepGrapeUI);
    /*styleName: Standard;*/
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.clip-menu-item {
    padding-left: 8px;
}

.clip-menu-item:hover {
    background: var(--midnight-LiteGrape);
}

.draggable-list-item {
    display: flex;
    justify-content: center;
}

.draggable-list-item:nth-child(2n-1) .clip-list-row {
    background: linear-gradient(90deg, var(--midnight-MainDarkBG) 0%, var(--midnight-CoolGrapeBG) 100%) padding-box, linear-gradient(90deg, var(--midnight-MainDarkBG) 0%, var(--midnight-CoolGrapeBG) 100%) border-box;
    border-width: 1px;
    border-style: solid;
}

.clip-list-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 15px * 2);
    height: 48px;
}

.clip-list-row::after {
    content: "";
}

.clip-list-row {
    border: 1px solid rgba(0,0,0,0);
    border-radius: 2px;
}

.clip-list-row-dragging {
    border: 1px solid rgba(0,0,0,0);
}

.clip-list-row-not-dragging:hover {
    border: 1px solid var(--midnight-LiteGrape);
}

.clip-menu-container {
    visibility: hidden;
    margin-right: -15px;
    position: absolute;
    right: 0px;
}

.clip-list-row:hover .clip-menu-container {
    visibility: visible;
    position: relative;
}


#upload-thumbnail {
    opacity: 0;
    position: fixed;
}

.clips-working {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(1,1,1,0.25);
    z-index: 1000;
}

.clip-or-project-first-column {
    margin-left: 0px;
    position: relative;
    display: flex;
    align-items: center;
}

.confirm-delete-clip-popup {
    display: inline-block;
    background: linear-gradient(90deg, var(--midnight-MainGreyFG) 0%, var(--midnight-WarmGrapeFG) 100%);
    color: var(--midnight-LiteUI);
    padding: 0px 30px 20px 30px;
    border-radius: 5px;
}
