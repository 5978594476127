.downloads-container {
    width: calc(100% - 120px);
    padding-left: 40px;
    height: 100%;
}

.downloads-container-cellmode {
    width: calc(100% - 60px);
    padding-left: 20px;
    height: 100%;
}

.downloads-container-left-padding {
    padding-left: 26px;
}

.downloads-container-top-rows-padding {
    padding-top: 16px;
    padding-bottom: 16px;
}

.downloads-container-right-padding {
    padding-right: 26px;
}

.row-container {
    border: 1px solid rgba(0,0,0,0);
    border-radius: 2px;
    height: 48px;
    align-content: center;
}

.row-container:hover {
    border: 1px solid var(--midnight-MidGrape);
}

.holosuite {
    padding-right: 10px;
}


.download-button {
    cursor: pointer;
    width: 164px;
    height: 36px;
    border: 2px solid rgba(0,0,0,0);
    border-radius: 3px;
    color: var(--midnight-MidGrape);
}

.download-button:active {
    color: var(--midnight-LiteUI) !important;
    border: 2px solid var(--midnight-LiteUI) !important;
}

.download-button:active .download-button-icon {
    /* convert image color to --midnight-LiteUI (#EDEBF5) */
    filter: brightness(0) saturate(100%) invert(96%) sepia(4%) saturate(476%) hue-rotate(207deg) brightness(99%) contrast(93%);
}

.download-button:hover {
    border: 2px solid var(--midnight-MidGrape);
    background: var(--midnight-DarkGrape);
}

.download-button-icon {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}

.button-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.version-label {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
}

.version-label-small {
    font-size: 20px;
    font-weight: 400;
    line-height: 28.8px;
    letter-spacing: 0em;
    text-align: left;
}

.version-label-smaller {
    font-size: 16px;
    font-weight: 400;
    line-height: 28.8px;
    letter-spacing: 0em;
    text-align: left;
}

.row-version-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 18.88px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 200px;
}

.LTS-label {
    padding-left: 10px;
    text-decoration: underline;
    font-weight: 400;
}

.LTS-label:hover ~ #LTS-sign-container {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s;
}

#LTS-sign-container:hover {
    visibility: visible;
    opacity: 1;
}
    
#LTS-sign-container {
    visibility: hidden;
    opacity: 0;
}

.LTS-sign {
    position: absolute;
    margin-left: -200px;
    margin-top: 22px;
    padding: 9px 18px 9px 18px;
    width: 349px;
    height: 38px;
    background-color: var(--midnight-MidGrape);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--midnight-DeepGrapeUI);
    border-radius: 3px;
    box-shadow: 0px 2px 8px 1px #522D5680;
}

.release-notes-button {
    cursor: pointer;
    width: 199px;
    height: 36px;
    border: 2px solid rgba(0,0,0,0);
    border-radius: 3px;
    color: var(--midnight-MidGrape);
}

.button-no-label {
    cursor: pointer;
    width: 48px;
    height: 36px;
    border: 2px solid rgba(0,0,0,0);
    border-radius: 3px;
}

.button-no-label:hover {
    border: 2px solid var(--midnight-MidGrape);
    background: var(--midnight-DarkGrape);
}

.button-no-label:active {
    border: 2px solid var(--midnight-LiteUI);
}

.button-no-label:active .download-button-icon {
    /* convert image color to --midnight-LiteUI (#EDEBF5) */
    filter: brightness(0) saturate(100%) invert(96%) sepia(4%) saturate(476%) hue-rotate(207deg) brightness(99%) contrast(93%);
}

.button-no-label:active .release-notes-button-icon {
    /* convert image color to --midnight-LiteUI (#EDEBF5) */
    filter: brightness(0) saturate(100%) invert(96%) sepia(4%) saturate(476%) hue-rotate(207deg) brightness(99%) contrast(93%);
}

.release-notes-button:hover {
    border: 2px solid var(--midnight-MidGrape);
    background: var(--midnight-DarkGrape);
}

.release-notes-button:active {
    color: var(--midnight-LiteUI) !important;
    border: 2px solid var(--midnight-LiteUI) !important;
}

.release-notes-button:active .release-notes-button-icon {
    /* convert image color to --midnight-LiteUI (#EDEBF5) */
    filter: brightness(0) saturate(100%) invert(96%) sepia(4%) saturate(476%) hue-rotate(207deg) brightness(99%) contrast(93%);
}

.release-notes-button-icon {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}

.h4-line {
    display: flex;
    flex-direction: row;
    margin-right: 0px;
}

.h4-line:after {
    content: " ";
    flex: 1 1;
    height: 2px;
    background: -webkit-linear-gradient(left, var(--midnight-MainGreyFG), var(--midnight-WarmGrapeFG));
    margin: auto
}

.download-button .hidden-child {
    visibility: hidden;
}

.download-button:hover .hidden-child {
    visibility: visible;
}

.release-notes-button .hidden-child {
    visibility: hidden;
}

.release-notes-button:hover .hidden-child {
    visibility: visible;
}

.downloads-view {
    /*override margin from viewport, it's set in downloads-container */
    width: 100vw;
    color: var(--midnight-LiteUI);
}