/* Hack (MS): make full-screen icon invisible and adjust volume control to fill space
    begin of holostream player css code
  top level container for a holostream player
  a holostream player consists of
    a viewport (the 3js camera)
    a controls group that contains the overlaid controls

  It is positioned relative to the any parent elements
 */
.hsp-player-container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    position: relative;
}

.hsp-player-container:focus {
    border: none;
}

/*
The hsp player is the canvas + the controller,
 */

.hsp-player {
    position: relative;
    width: 100%;
    height: 100%;
}

#hsp-viewport-container {
    width: 100%;
    height: 100%;
}

/*
  The controls at the bottom of the 3js cameras, this is meant to be include within the same layout as the viewport
  container so that we can use absolute positioning to put floating over the main canvas
 */
.hsp-controls-container {
    position: absolute;
    bottom: 0px;
    padding: 0px;
    margin: 0px;
    width: 100%; /* make sure our control bar always stretches across the full width of our viewport */
    height: 60px;
    background: linear-gradient(rgba(1, 0, 0, 0), rgba(1, 1, 0, 0.7));
}

/*
  a five column layout with the second column set to fill the range -- designed specifically for the playback controls
  that consist of:
      <play/pause button> <seek control> <time counter> <audio mute & volume slider> <full screen button>
 */
.hsp-controls-grid {
    display: grid;
    position: relative;
    grid-template-columns: 18px auto 50px 18px 85px 18px;
    align-items: center;
    column-gap: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    background: transparent;
}

.hsp-control-container {
    position: relative;
    color: white;
    fill: white;
}

/* shared styling for all holostream player controls */
.hsp-control {
    border: none;
    border-radius: 3px;
    background: transparent;
    color: #fff;
    cursor: pointer;
    flex-shrink: 0;
    overflow: visible;
}

    .hsp-control:focus {
        outline: none;
    }

    .hsp-control:hover span:after {
        opacity: 1;
        color: white;
    }

.hsp-control-play {
    background: transparent;
    border: none;
}

.hsp-control-play-icon {
    left: 0px;
    bottom: 5px;
    position: absolute;
    alignment: left;
    align-items: center;
    background: transparent;
}

.hsp-control-pause-icon {
    left: 0px;
    bottom: 5px;
    position: absolute;
    alignment: left;
    align-items: center;
    background: transparent;
}

/* because of how height offsets work with sliders we need to slightly offset the slider container from the bottom */
.hsp-control-seek-container {
    bottom: 5px;
}
/* range slider for the seek control */
.hsp-control-seek {
    -webkit-appearance: none; /* get rid of all web kit styles so that we have uniform appearance of slider */
    width: 100%;
    height: 6px;
    bottom: 30px;
    background: transparent;
    cursor: pointer;
    border-radius: 5px; /* iOS */
    color: rgba(0, 0, 0, 0.5);
}

    /* sets the thumb for chrome, firefox, edge */
    .hsp-control-seek::-webkit-slider-thumb {
        background-color: rgba(255, 255, 255, 1);
        appearance: none;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: white;
        cursor: pointer;
        margin-top: -5px; /* adjust position of thumb in chrome, and opera */
    }

    /* sets the thumb for firefox */
    .hsp-control-seek::-moz-range-thumb {
        background-color: rgba(255, 255, 255, 1);
        width: 14px;
        height: 14px;
        margin: 0px; /* override webkit-slider-thumb in edge */
    }

    /* sets the track for chrome */
    .hsp-control-seek::-webkit-slider-runnable-track {
        background-color: rgba(255, 255, 255, 0.25);
        height: 6px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: box-shadow 0.3s ease;
        transition-property: box-shadow;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
    }

    .hsp-control-seek::-webkit-slider-progress {
        background-color: rgba(0, 0, 0, 0.5);
        height: 6px;
        border-radius: 5px;
        border: none;
    }

    .hsp-control-seek::-moz-range-progress {
        background-color: rgba(0, 0, 0, 0.5);
        height: 6px;
        border-radius: 5px;
        border: none;
    }

    .hsp-control-seek::-moz-range-track {
        background-color: rgba(255, 255, 255, 0.25);
        height: 6px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: box-shadow 0.3s ease;
        transition-property: box-shadow;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
    }

    .hsp-control-seek::-ms-track {
        background-color: rgba(255, 255, 255, 0.25);
        height: 6px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: box-shadow 0.3s ease;
        transition-property: box-shadow;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
    }

    .hsp-control-seek::-ms-fill-lower {
        background: yellow;
        height: 6px;
        border-radius: 5px;
        border: none;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }

    .hsp-control-seek::-ms-fill-upper {
        background: rgba(0, 0, 0, 0.5);
        height: 6px;
        border-radius: 5px;
        border: none;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }

/* a two column grid for the audio control */
.hsp-audio-grid {
    display: grid;
    margin: 0px;
    padding: 0px;
    grid-template-columns: 18px auto;
    position: relative;
    align-items: center;
    left: 0px;
    grid-column-gap: 8px;
}

.hsp-audio-grid-cell {
    width: auto;
    padding: 0px;
    margin: 0px;
}

.hsp-control-audio-enabled-icon {
    left: 0px;
    bottom: 5px;
    position: absolute;
    alignment: left;
    align-items: center;
    background: transparent;
}

.hsp-control-audio-disabled-icon {
    left: 0px;
    bottom: 5px;
    position: absolute;
    alignment: left;
    align-items: center;
    background: transparent;
}

.hsp-time-container {
    color: white;
    font-size: 14px;
    font-family: Avenir, "Avenir Next", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    text-align: center;
    text-justify: auto;
    font-variant-numeric: tabular-nums;
    font-weight: 500;
    line-height: 1.7;
    text-shadow: none;
    padding-bottom: 12px;
}

/* because of how height offsets work with sliders we need to slightly offset the slider container from the bottom */
.hsp-control-volume-container {
    bottom: 5px;
    left: 10px;
}
/* range slider to control volume */
.hsp-control-volume {
    -webkit-appearance: none; /* get rid of all web kit styles so that we have uniform appearance of slider */
    width: 80px;
    height: 6px;
    bottom: 4px;
    background: "linear-gradient(to right, #5d5a5e 0%, #5d5a5e 50%, #aeaeae 50%, #aeaeae 100%)";
    cursor: pointer;
    border-radius: 6px; /* iOS */
}

    /* sets the thumb for chrome, firefox, edge */
    .hsp-control-volume::-webkit-slider-thumb {
        background-color: rgba(255, 255, 255, 1);
        appearance: none;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: white;
        cursor: pointer;
        margin-top: -5px; /* adjust position of thumb in chrome, and opera */
    }

    /* sets the thumb for firefox */
    .hsp-control-volume::-moz-range-thumb {
        background-color: rgba(255, 255, 255, 1);
        height: 14px;
        width: 14px;
        margin: 0px; /* override webkit-slider-thumb in edge */
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
    }

    /* sets the track for firefox */
    .hsp-control-volume::-webkit-slider-runnable-track {
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 5px;
        height: 6px;
    }

    .hsp-control-volume::-moz-range-progress {
        background-color: rgba(0, 0, 0, 0.5);
        height: 6px;
        border-radius: 5px;
        border: none;
    }

    .hsp-control-volume::-moz-range-track {
        background-color: rgba(255, 255, 255, 0.25);
        height: 6px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: box-shadow 0.3s ease;
        transition-property: box-shadow;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
    }

/* specific positioning for the maximize and minimize icons */
.hsp-control-maximizeFullScreen-icon {
    left: 5px;
    bottom: 5px;
    position: absolute;
    alignment: left;
    align-items: center;
    opacity: 0;
}

.hsp-control-minimizeFullScreen-icon {
    left: 5px;
    bottom: 5px;
    position: absolute;
    alignment: left;
    align-items: center;
}

/* custom image styling applied to any svg elements used to represent an icon */
.hsp-icon {
    fill: white;
}
/* We use these clases to hide and show elements on the page */
.hsp-hide-element {
    visibility: hidden;
}

.hsp-display-element {
    visibility: visible;
}

.hsp-buffering-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    pointer-events: none;
}

.hsp-buffering-icon {
    pointer-events: none;
    opacity: 100%;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 2000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 2000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 2000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.hsp-large-play-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    pointer-events: none;
    opacity: 100%;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.hsp-large-play-button {
    pointer-events: all;
}

.hsp-large-play-button-inactive {
    pointer-events: none;
}

.hsp-transparent {
    opacity: 0%;
    pointer-events: none;
}

#hsp-options-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    background: #424242;
    border-radius: 4px;
    color: white;
    z-index: 1;
    bottom: 30px;
    right: 10;
    visibility: "hidden";
}

    #hsp-options-modal > ul {
        margin-block-start: 0 !important;
        margin-block-end: 0 !important;
        padding-inline-start: 0 !important;
    }

ul {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    padding-inline-start: 0 !important;
}

#hsp-options-button {
    position: relative;
    left: -8px;
}

#hsp-options-list {
    list-style: none;
}

    #hsp-options-list > li {
        width: 150px;
    }

.hsp-options-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    font-size: 14px;
}

    .hsp-options-item > input {
        cursor: pointer;
    }

#options-toggle-button {
    float: right;
}

#options-toggle-button-label {
    float: left;
}
/* End of holostream player css code */
